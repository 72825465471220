import React from "react" 

const headerPage = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-aspireblue fixed-top" id="top" >
      {/* <img src="x-head.png" className="x-head" /> */}
      <div className="container z-9">

        <a className="navbar-brand" href="https://www.sumadhuragroup.com/" target="_blank">
          <img src="../Images/sum.png" className="nav-image" alt="icon" style={{ width: "90px" }} /></a>
        <a className="navbar-brand" href="/">
          <img src="../Images/h-asp.png" className="nav-image" alt="icon" style={{ width: "99px" }} />
        </a>
        <button className="navbar-toggler nav-white" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link nav-a xoox" href="/about">ABOUT</a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link  nav-a dropdown-toggle nav-item" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                PROJECTS
             </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ backgroundColor: "#2D64AB" }}>
                <a className="nav-link  nav-a dropdown-toggle " href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  BANGALORE
             </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ backgroundColor: "#2D64AB" }}>
                  <a className="dropdown-aurum" href="/aurum" style={{borderBottom: "1px solid rgba(0,0,0,.15)"}}>Aspire Aurum</a>
                  <a className="dropdown-aurum" href="/amber">Aspire Amber</a>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link nav-a" href="/nri" >NRI </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link nav-a" href="career.html">CAREERS</a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link nav-a" href="/partner" >PARTNER</a>
            </li>
            <li className="nav-item">
              <a className="nav-link nav-a" href="/refer">REFER US</a>
            </li>
            <li className="nav-item">
              <a className="nav-link nav-a" href="/contact">CONTACT US</a>
            </li>
            <li className="nav-item mt3">
              <a className="nav-link" href="https://forms.gle/MqM4UqxYgySfuJN99" target="_blank"><span className="header-navBtn xoxo" >E-VISIT</span></a>
            </li>
            <li className="nav-item mt3">
              <a className="nav-link" href="tel:+918431999444"><span className="header-navBtn">
                <span className="phone-fa"><i className="fa fa-phone" aria-hidden="true"></i>
                </span>+91 8431 999 444</span></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
export default headerPage