import React from "react"

const footerPage = () => {
    return (
        <div className="footer" >
            <div className="container">
                <div className="row">
                    {/* Section 1 */}
                    <div className="col-md-3  ">
                        <ul className="footer-header-ul">
                            <li>
                                <div className="row">
                                    <div className="col-md-12 text-center" style={{ paddingRight: "40px" }}>
                                        <img src="../Images/sum.png" style={{ width: "90px" }} />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="row">
                                    <p className="footer-para">Creating landmarks across the skyline of Bengaluru and Hyderabad, the team at Sumadhura lnfracon Pvt. Ltd. are happy to be 'homemakers' to a rising number of residents in premier properties across South India.  The Sumadhura Signature of Success is centred around these core values: the ability to deliver luxurious housing projects on-time, across hand-picked locations, at honest prices while ensuring that the quality of construction is impeccable. </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* Section 2 */}
                    <div className="col-md-3">
                        <div className="row">
                            <ul className="footer-header-ul">
                                <li>SUPPORT</li>
                            </ul>
                        </div>
                        <div className="row">
                            <ul className="footer-ul">
                                <a href="/contact"><li>Get in Touch</li></a>
                                <a href="/brochure"><li>Get Free Brochure</li></a>
                                <a href="/emi"><li>EMI Calculator</li></a>
                                <a href="https://www.sumadhurafoundation.org/"><li>Corporate Social Responsibility</li></a>
                                <a href="../sitemap.xml"><li>Site Map</li></a>
                            </ul>
                        </div>
                    </div>
                    {/* Section 3 */}
                    <div className="col-md-3">
                        <div className="row">
                            <ul className="footer-header-ul">
                                <li >OTHER PROJECTS</li>
                            </ul>
                        </div>
                        <div className="row">
                            <ul className="footer-ul">
	                        <a href="/amber"><li>Aspire Amber</li></a>
	    			<a href="https://gardensbythebrook.com/" target="_blank"><li>Gardens by the Brook</li></a>
	                        <a href="https://foliumbysumadhura.com" target="_blank"><li>Folium By Sumadhura</li></a>
	    			<a href="https://theolympus.in" target="_blank"><li>The Olympus</li></a>
	    			<a href="https://sumadhuragroup.com/project/sumadhura-sushantham-phase-2/" target="_blank"><li>Sumadhura Sushantham Phase-2</li></a>
	    			<a href="https://sumadhuragroup.com/project/sumadhura-sushantham-phase-1/" target="_blank"><li>Sumadhura Sushantham Phase-1</li></a>
	                        <a href="https://sumadhuragroup.com/project/sumadhura-eden-garden/" target="_blank"><li>Sumadhura Eden Garden</li></a>
	    			<a href="https://sumadhuragroup.com/project/sumadhura-horizon/" target="_blank"><li>Sumadhura Horizon</li></a>
	                        <a href="https://sumadhuragroup.com/project/sumadhura-nandanam/" target="_blank"><li>Sumadhura Nandanam</li></a>
                            </ul>
                        </div>
                    </div>
                    {/* $ection 4 */}
                    <div className="col-md-3">
                        <div className="row">
                            <ul className="footer-header-ul">
                                <li >CONTACT INFO</li>
                            </ul>
                        </div>
                        <div className="row" >
                            <ul className="footer-ul" >
                                <li className="co-off">Corporate office</li>
                                <li>Sy.No.108/2, Millenia building,</li>
                                <li>1st Main, MSR Layout,</li>
                                <li>Munnekollala Village,</li>
                                <li>Marathahalli Outer Ring Road, </li>
                                <li>Bengaluru - 560037</li><br />
                                <a href="mailto:enquiries@realvaluehomes.in" target="_blank"><li> <i className="fa fa-envelope" aria-hidden="true"></i>&nbsp;&nbsp; enquiries@realvaluehomes.in</li></a>
                                <a href="tel:+91 8431 999 444" target="_blank"> <li><i className="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp; +91 8431 999 444</li></a>
                                <br />
                                <li>
                                    <a href="https://www.facebook.com/Aspire-Series-A-Sumadhura-Venture-106528511410714" target="_blank">
                                        <span className="footer-social">
                                            <i className="fa fa-facebook-official" aria-hidden="true"></i>
                                        </span></a>
                                    <a href="https://www.instagram.com/aspire__series/" target="_blank">
                                        <span className="footer-social">
                                            <i className="fa fa-instagram" aria-hidden="true"></i>
                                        </span></a>
                                    <a href="https://www.youtube.com/c/Sumadhuragroupbuilders " target="_blank">
                                        <span className="footer-social">
                                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                                        </span></a>
                                    <a href="https://twitter.com/teamsumadhura " target="_blank">
                                        <span className="footer-social">
                                            <i className="fa fa-twitter" aria-hidden="true"></i>
                                        </span></a>
                                    <a href="https://www.linkedin.com/company/sumadhura-infracon-pvt--ltd " target="_blank">
                                        <span className="footer-social">
                                            <i className="fa fa-linkedin" aria-hidden="true"></i>
                                        </span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row footer-end">
                <div className="container">
		    <div className="col-md-6">
                        <p className="p-2 float-left">@{(new Date().getFullYear())} Sumadhura Groups - Aspire all Rights Reserved</p>
		    </div>
		    <div className="col-md-6 float-right">
		        <p className="p-2 float-right"><a href="/privacy_policy">Privacy Policy</a></p>
		    </div>
                </div>
            </div>
         </div>
    )
}

export default footerPage
