import React from "react" 

const popform = () => {
    return (
         <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content"> 
                    <div className="modal-body">
                        <div className="col-md-12">
                            <h5 className="text-center aspireblue pt-3">ENQUIRE NOW</h5><br />
                            <input type="text" placeholder="Name" className="form-control" id="contact_name-enq" /><br />
                            <input type="text" placeholder="Email" className="form-control" id="contact_email-enq" /><br />
                            <input type="number" placeholder="Phone" className="form-control" id="contact_phone-enq" /><br />
                            <select className="form-control" id="contact_source-enq">
                                <option selected disabled>How did you know about us?</option>
                                <option>Hoarding</option>
                                <option>Web Ads</option>
                                <option>Facebook</option>
                                <option>E-mails</option>
                                <option>SMS</option>
                                <option>Newspapers</option>
                                <option>T.V. Ads</option>
                            </select><br />
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="4" placeholder="Message" id="contact_message-enq"></textarea><br />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary bg-aspirered border-0" data-dismiss="modal" id="enq-close">Close</button>
                        <button type="button" className="btn btn-primary bg-aspireblue border-0"  id="enq-btn">Submit Enquiry</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default popform
