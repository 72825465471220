import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Pop from "../components/popform"
import "../components/components.css"
import Helmet from "react-helmet"


const Layout = (props) => {
    return (
        <div>
            <Helmet>
                <meta name="viewport" content="initial-scale=1.0" />
                <meta name="description" content="The Aspire Series was born out of Sumadhura's desire to give wings to young Indians' dreams of owning a home." />
                <meta property="og:url" content="https://realvaluehomes.in/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="ASPIRE" />
                <meta property="og:description" content="The Aspire Series was born out of Sumadhura's desire to give wings to young Indians' dreams of owning a home." />
                <meta property="og:image" content="https://realvaluehomes.in/Images/1.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="realvaluehomes.in" />
                <meta property="twitter:url" content="https://realvaluehomes.in/" />
                <meta name="twitter:title" content="ASPIRE" />
                <meta name="twitter:description" content="The Aspire Series was born out of Sumadhura's desire to give wings to young Indians' dreams of owning a home." />
                <meta name="twitter:image" content="https://realvaluehomes.in/Images/1.png" />
	        <meta name="facebook-domain-verification" content="f15ecmxv65ot5tgcnyqu8sjt1uhuqc" />
                <link rel="icon" href="../Images/1.png" type="image/x-icon"></link>
                <meta property="og:image" content="../images/1.png" />
                <script src="https://code.jquery.com/jquery-3.5.1.min.js" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" crossorigin="anonymous" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                <script src="https://cdn.jsdelivr.net/npm/js-cookie@rc/dist/js.cookie.min.js" />
                <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js" integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV" crossorigin="anonymous" />
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z" crossorigin="anonymous" />
                <title>ASPIRE</title>
	    <script src="../Scripts/facebook.js"></script>
	    <script src="../Scripts/google.js"></script>
            </Helmet>
            <link rel="icon" href="../Images/1.png" type="image/x-icon" />
            <button id="myBtn" title="Go to top"><i className="fa fa-arrow-up" aria-hidden="true"></i></button>
            <button type="button" className="float-btn" data-toggle="modal" data-target="#exampleModal">Enquire Now</button>
            <Header />
            <Pop />
            {props.children}
            <Footer />
            <script src="../Scripts/scroll.js"></script>
            <script src="../Scripts/enquiry_mail.js"></script>
        </div>
    )
}

export default Layout
